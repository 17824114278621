/*IMPORT FONT*/
/* font-family: 'Laila', sans-serif;
font-family: 'Mynerve', cursive; */
@import url('https://fonts.googleapis.com/css2?family=Laila:wght@500&family=Mynerve&display=swap');

/*GLOBALS*/
:root {
    --color1: rgba(127, 51, 14, 0.76);
    --color2: rgb(129, 123, 123);
    --color3: rgb(251, 110, 94);
    --color4: rgb(250, 235, 215);
    --font1: 'Mynerve', cursive;
    --font2: 'Laila', sans-serif;
    --shadowBox: 3px 3px 5px 0px rgba(0, 0, 0, 0.75);
    --shadowText: 3px 3px 2px rgba(0, 0, 0, 0.67);
}

* {
    margin: 0px;
    padding: 0px;
}

/*ANIMATIONS*/
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*IMPORT CSS MOBILE FIRST*/
@import url('mobile.css');
@import url('tablet.css') screen and (min-width: 768px);
@import url('desktop.css') screen and (min-width: 1024px);