svg {
    width: 8vw;
    height: 8vw;
    transition: 0.3s;
}

body {
    background: linear-gradient(109deg, var(--color1) 0%, #441002 100%);
    color: var(--color4);
    width: 100%;
    overflow-y: scroll;
}

span {
    color: var(--color3);
}

/***********HEADER********************/

header {
    width: 95%;
    margin: 1vw auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

h1 {
    font-family: var(--font1);
    text-shadow: var(--shadowText);
    font-size: 7vw;
    line-height: 8vw;
}

header p {
    font-size: 3.5vw;
    line-height: 4vw;
}

#headerOptions {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

#welcomer {
    font-size: 2.5vw;
    text-align: end;
}

#headerNav {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
}

#headerNav svg:hover {
    fill: rgb(250, 89, 71);
    transition: 0.3s;
}

/**********MAIN********************/

main {
    width: 100%;
    margin: 5px auto;
}

section {
    display: block;
    background: var(--color2);
    border-radius: 10px;
    box-shadow: var(--shadowBox);
    margin: 3vw 1vw 0 1vw;
    padding: 5px;
    font-size: 4vw;
}

input,
select {
    font-size: 4vw;
}

h2 {
    margin: 2vw;
    font-size: 5vw;
    line-height: 6vw;
    text-shadow: var(--shadowText);
}

/**********SITE********************/

.place {
    margin: 2vw;
}

.previewers {
    margin: 0.5vw;
    display: flex;
    flex-direction: column;
}

.previewerLead {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--color4);
    border-radius: 2vw;
    overflow: hidden;
}

.previewersList {
    margin-top: 1vw;
    display: flex;
    justify-content: space-between;
    gap: 1vw;
}

.previewer {
    width: 20%;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--color4);
    border-radius: 2vw;
}

.previewer img,
.previewerLead img {
    max-width: 100%;
    max-height: 100%;
}

.credits {
    margin: 1vw;
    text-align: end;
    font-size: 2vw;
}

.informer {
    margin: 1vw;
}

.accordionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2vw;
    background-color: var(--color4);
    color: var(--color2);
    margin: 2vw 0 2vw 0;
    padding: 0 2vw 0 2vw;
    border-radius: 2vw;
}

.accordionHeader svg {
    fill: var(--color2);
}

.accordionContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 0;
    overflow: hidden;
    margin: 0 2vw 0 2vw;
}

.accordionContent.open {
    height: fit-content;
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.accordionContent div {
    max-width: 95%;
}

.post {
    margin: 2vw 0 0 0;
    padding: 1vw;
    border-radius: 2vw;
    border: var(--color4) 0.5vw solid;
}

#postWriting {
    margin: 2vw 0 0 0;
    display: flex;
    flex-direction: column;
}

#postWriting textarea,
#postWriting button {
    border-radius: 2vw;
    border: var(--color4) 0.5vw solid;
    margin: 1vw 0 1vw 0;
    padding: 0.5vw;
    background: var(--color3);
    color: var(--color4);
}

/**********ACCUEIL********************/

#album {
    position: relative;
    width: 90%;
    margin: 0 auto;
    height: 50vw;
    max-width: 1258px;
    max-height: 745px;
}

#album img {
    width: 26vw;
    position: absolute;
    border: var(--color3) 0.1vw solid;
    border-radius: 1vw;
    max-width: 388px;
}

#accueilSection p {
    margin: 2vw;
}

#album1 {
    top: 0%;
    left: 0%;
    transform: rotate(1deg);
}

#album2 {
    top: 1%;
    left: 62%;
    transform: rotate(-2deg);
}

#album3 {
    top: 30%;
    left: 1%;
    transform: rotate(-2deg);
}

#album4 {
    top: 1%;
    left: 31%;
    transform: rotate(1deg);
}

#album5 {
    top: 30%;
    left: 63%;
    transform: rotate(2deg);
}

#album6 {
    top: 65%;
    left: 0.5%;
    transform: rotate(-2deg);
}

#album7 {
    top: 60%;
    left: 61%;
    transform: rotate(-1deg);
}

/**********SEARCH********************/

#searchSectionContent {
    margin: 2vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1vw;
}

#searchSection button {
    border-radius: 2vw;
    border: var(--color4) 0.5vw solid;
    margin: 1vw auto;
    padding: 1vw;
    background: var(--color3);
    color: var(--color4);
}

#searchForm {
    width: 100%;
}

#searchSection p {
    display: flex;
    align-items: center;
    gap: 1vw;
}

.btn-search {
    width: 100%;
    display: flex;
    justify-content: center;
}

#searchSection select {
    border-radius: 2vw;
    border: var(--color4) 0.5vw solid;
    margin: 1vw 0 1vw 0;
    padding: 0.5vw;
    background: var(--color3);
    color: var(--color4);
}

.leaflet-container {
    margin: 0.5vw;
    width: 99%;
    height: 120vw;
    border-radius: 2vw;
}

/**********CREATE**************************/

#createSection {
    min-height: 90vh;
}

#createSection form {
    margin: 2vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 1vw;
}

#createSection .input-container {
    width: 100%;
}

#input-name,
#input-description {
    width: 99%;
}

#input-department,
#input-city,
#input-type,
#input-department {
    width: 100%;
}

#input-description {
    border-radius: 2vw;
    border: var(--color4) 0.5vw solid;
    margin: 1vw 0 1vw 0;
    padding: 0.5vw;
    background: var(--color3);
    color: var(--color4);
}

#createSection select {
    border-radius: 2vw;
    border: var(--color4) 0.5vw solid;
    margin: 1vw 0 1vw 0;
    padding: 0.5vw;
    background: var(--color3);
    color: var(--color4);
}

#createSection .input-container {
    position: relative;
}

#createSection .btn-trash {
    position: absolute;
    top: 17vw;
    left: 20vw;
}

div.photoPreview {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 17vw;
    height: 17vw;
    background: var(--color2);
    border-radius: 2vw;
    border: solid 1px var(--color3);
    overflow: hidden;
}

.photoPreview img {
    max-width: 100%;
    max-height: 100%;
}

#createSection input:not([type="file"]) {
    border-radius: 2vw;
    border: var(--color4) 0.5vw solid;
    margin: 1vw 0 1vw 0;
    padding: 0.5vw;
    background: var(--color3);
    color: var(--color4);
}

#createSection button {
    border-radius: 2vw;
    border: var(--color4) 0.5vw solid;
    margin: 1vw auto;
    padding: 1vw;
    background: var(--color3);
    color: var(--color4);
}

#createSection svg {
    fill: var(--color4);
    width: 5vw;
    height: 5vw;
}

#createSection input[type="file"]::file-selector-button {
    border-radius: 2vw;
    border: var(--color4) 0.5vw solid;
    margin: 1vw 0 1vw 0;
    padding: 1vw;
    background: var(--color3);
    color: var(--color4);
}

#createSection input[type="number"] {
    width: 10vw;
    padding: 1vw;
}


#mapForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#mapForm p {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1vw;
}

#mapForm input[type="number"] {
    width: 30vw;
}

#createSection .leaflet-container {
    width: 100%;
    height: 120vw;
    border-radius: 2vw;
}

.progressBar {
    width: 90vw;
    border-radius: 1vw;
}

.progressBar p {
    text-align: center;
}

.progressBar-value {
    background-color: var(--color3);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color3);
}

.progressBar-timeAdvertiser {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.progressBar-timeAdvertiser p {
    text-align: center;
}

#ps {
    width: 100%;
    text-align: center;
}

.ifNotConnected {
    padding-top: 5vw;
    text-align: center;
}

.linkToConnect {
    margin: 5vw auto;
    width: fit-content;
}

.linkToConnect a {
    padding: 1vw 1vw;
    color: var(--color4);
    text-decoration: none;
}

/**********CONNEXION*******************/

#connexionSection {
    min-height: 80vh;
}

#connexionSection form {
    margin: 0 2vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#connexionSection p,
#connexionSection button {
    margin: 2vw auto;
    text-align: center;
}

#connexionSection div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#loginMsgError,
#signinMsgError,
#nextMdpMsgError {
    color: red;
}

#connexionSection input {
    border-radius: 2vw;
    border: var(--color4) 0.5vw solid;
    margin: 1vw 0 1vw 0;
    padding: 0.5vw;
    background: var(--color3);
    color: var(--color4);
}

#connexionSection button {
    border-radius: 2vw;
    border: var(--color4) 0.5vw solid;
    margin: 1vw auto;
    padding: 1vw;
    background: var(--color3);
    color: var(--color4);
}

/**********CHARTE******************/

.shartContainer {
    margin: 2vw;
    display: flex;
    flex-direction: column;
}

.shartContainer p,
.shartContainer ul,
.shartContainer h3 {
    padding: 0.5vw;
}

.shartContainer ul li {
    list-style: none;
}

/**********FOOTER******************/

footer {
    margin: 4vw auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

footer a {
    color: var(--color4);
}