body {
    max-width: 1440px;
    margin: auto;
    background: url(/public/images/background.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

section {
    background-color: rgba(129, 123, 123, 0.646);
    border-radius: 3vw;
}

p,
label,
select,
input {
    font-size: 1.5vw;
}

svg {
    width: 4vw;
    height: 4vw;
}

#welcomer {
    font-size: 1vw;
}

section {
    font-size: 1vw;
}

h2 {
    font-size: 2vw;
    line-height: 3vw;
}

input:hover,
button:hover,
select:hover {
    cursor: pointer;
}

/**********SEARCH**********/

#searchSection select,
#searchSection button {
    padding: 0.5vw;
    margin: 0.5vw 0;
    border: 3px solid var(--color4);
}

.leaflet-container {
    height: 800px;
}

/**********CREATE***************************/

#createSection select,
#createSection button,
#createSection textarea,
#createSection input:not([type="file"]) {
    padding: 0.5vw;
    margin: 0.5vw 0;
    border: 3px solid var(--color4);
}

div.photoPreview {
    width: 10vw;
    height: 10vw;
    border-radius: 20px;
}

#mapForm {
    margin: 0 auto;
}

#createSection .leaflet-container {
    height: 800px;
}

.leaflet-control-attribution {
    display: none;
}

/**********ACCUEIL**********************/